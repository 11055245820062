import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const AppHeader = ({ showBackButton, showSettings, showLogout, logoUrl }) => {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const [userPermissions, setUserPermissions] = useState({
        isAdmin: false,
        canAccessHealthCheck: false,
        canAccessSlideshows: false,
        canAccessDealsheets: false,
        canAccessCustomerMenu: false,
        canAccessQueue: false,
        canAccessQrTracker: false,
        canAccessReorderReport: false,
    });

    useEffect(() => {
        fetchStores();
        setSelectedStoreFromLocalStorage();
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/current_user/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setUserPermissions({
                isAdmin: data.is_admin,
                canAccessHealthCheck: data.can_access_health_check,
                canAccessSlideshows: data.can_access_slideshows,
                canAccessDealsheets: data.can_access_dealsheets,
                canAccessCustomerMenu: data.can_access_customer_menu,
                canAccessQueue: data.can_access_queue,
                canAccessQrTracker: data.can_access_qr_tracker,
                canAccessReorderReport: data.can_access_reorder_report,
            });
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error.message));
    }, []);
    

    const fetchStores = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(res => res.json())
        .then(data => setStores(data))
        .catch(error => console.error("Error fetching stores:", error));
    };

    const setSelectedStoreFromLocalStorage = () => {
        const savedStore = JSON.parse(localStorage.getItem('selectedStore'));
        if (savedStore) setSelectedStore(savedStore);
    };

    const handleSelectStore = (store) => {
        setSelectedStore(store);
        localStorage.setItem('selectedStore', JSON.stringify(store));
        localStorage.setItem('Selected-Store-Id', store.id);
        window.location.reload();
    };

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const handleSettings = () => {
        navigate('/settings');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="d dash-settings-container">
            <div className='app-header-buttons-right topleftbuttons buttons-div'>
                {showBackButton && (
                    <button onClick={handleBack}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                    </button>
                )}
                <button onClick={toggleDropdown}>
                    {selectedStore ? selectedStore.name : "Select Store"}
                </button>
                {isDropdownVisible && (
                <div ref={dropdownRef} className="dropdown">
                    {stores.map(store => (
                        <div key={store.id} onClick={() => handleSelectStore(store)}>
                            {store.name}
                        </div>
                    ))}
                </div>
            )}
            </div>

            <div className="logo-div">
                <img src={logoUrl} alt="Logo" className="dash-logo-app-header" />
            </div>

            <div className="app-header-buttons-right buttons-div">
                {showSettings && userPermissions.isAdmin && (
                    <button onClick={handleSettings}>
                        <i className="fa fa-cog" aria-hidden="true"></i> Settings
                    </button>
                )}
                {showLogout && (
                    <button onClick={handleLogout}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
                    </button>
                )}
            </div>
        </div>
    );
};

export default AppHeader;
